import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import wso2 from "../img/footer-client-wso2@2x.png"
import joinem from "../img/footer-client-joinem@2x.png"
import pypestream from "../img/footer-client-pypestream@2x.png"
import clubsoirte from "../img/footer-client-clubsoirte@2x.png"
import genesis from "../img/footer-client-genesis@2x.png"
import afrika from "../img/footer-client-afrika@2x.png"

import screencastApp from '../img/screencast-app.mp4'
import screencastWebsite from '../img/screencast-website.mp4'
import screencastUI from '../img/screencast-interface.mp4'

export default ({ location, data }) => {
  return (
    <Layout location={location}>
      <div className="cover-wrapper cover-homepage bg-blue">
        <div className="cover-wrapper-inner">
          <div className="cover-container">
            <div className="inner cover">
              <div
                id="heading-carousel"
                className="carousel slide carousel-fade"
              >
                <div className="carousel-inner" role="listbox">
                  <div className="item active">
                    <h1 className="cover-heading">We build apps.</h1>
                  </div>
                  <div className="item ">
                    <h1 className="cover-heading">We build websites.</h1>
                  </div>
                  <div className="item ">
                    <h1 className="cover-heading">We design interfaces.</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="mastfoot">
              <div className="inner">
                <div className="mouse-wrapper">
                  <div className="mouse">
                    <div className="mouse-scroll"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-overlay">
              <div id="screencast-carousel" className="carousel slide">
                <div className="carousel-inner full-height" role="listbox">
                  <div className="item active full-height">
                    <div className="screencast screencast-app">
                      <video autoPlay="autoplay" muted playsInline loop={true} style={{pointerEvents: "none"}}>
                        <source src={screencastApp} type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                  <div className="item full-height">
                    <div className="screencast screencast-website">
                      <video autoPlay="autoplay" muted playsInline loop={true} style={{pointerEvents: "none"}}>
                        <source src={screencastWebsite} type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                  <div className="item full-height">
                    <div className="screencast screencast-interface">
                      <video autoPlay="autoplay" muted playsInline loop={true} style={{pointerEvents: "none"}}>
                        <source src={screencastUI} type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cat">
                <div className="cat-eyes"></div>
              </div>
            </div>
            <div className="spacer"></div>
          </div>
        </div>
      </div>
      <div className="home-products ">
        <div className="container relative">
          <div className="row ">
            <div className="col-sm-6">
              <div className="box box-home-1 bg-yellow hidden-xs"></div>
            </div>
            <div className="section-content col-sm-6">
              <h1 className="section-title section-title-large sm-m-b-20 right">
                Building own
                <br />
                products
              </h1>
              <p className="section-intro">
                We’re a bunch of dreamers, thinkers, designers, coders &amp;
                caffeine-addicts who have a burning desire to build stuff that
                matters. Over the recent past we’ve worked on products such as{" "}
                <a rel="noopener" href="https://themeforest.net/item/pages-admin-dashboard-template-web-app/9694847" target="_blank">
                  Pages
                </a>
              </p>
              <div className="box box-home-1 bg-yellow visible-xs"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-services relative sm-m-t-50">
        <div className="container relative">
          <div className="services-bg"></div>

          <div className="row ">
            <div className="section-content col-sm-6">
              <h1 className="section-title section-title-large sm-m-b-20 left">
                Helping startups &amp; companies
              </h1>
              <p className="section-intro">
                We work closely with startups &amp; companies to help them
                implement digital solutions with our expertise in product
                strategy, brand identity, UI/UX design and web/mobile
                development. Check out our creative <Link to="/clients">client-work</Link>. Already convinced?{" "}
                <a href="#" data-toggle-contact>
                  Let’s talk!
                </a>{" "}
              </p>
              <div className="box box-home-2 bg-green visible-xs"></div>
            </div>
            <div className="col-sm-6">
              <div className="box box-home-2 bg-green hidden-xs"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container m-t-60">
        <div className="footer-logos">
          <img alt="WSO2 logo" src={wso2} width="103" height={40} />
          <img alt="Pypestream logo" src={pypestream} width="201" height={40} />
          <img alt="Joinem logo" src={joinem} width="111" height={41} />
          <img alt="Genesis Media logo" src={genesis} width="176" height={36} />
          <img alt="Superior Afrika logo" src={afrika} width="109" height={51} />
          <img alt="Club Soirte logo" src={clubsoirte} width="71" height={70}  />
        </div>
      </div>

      <div className="bg-master-lightest m-t-60 p-t-40 p-b-40 newsletter-form">
        <div className="container">
          <div 
            className="row"
          style={{
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'space-between'
          }}>
            <div className="col-sm-8">
              <h2 className="m-t-5">
                Latest &amp; greatest, right into your inbox
              </h2>
            </div>
            <div className="col-sm-4">
              <form
                action="http://smashtaps.us11.list-manage.com/subscribe/post?u=f33e2360a713fdf68a46a5956&amp;id=a9617170fc"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate sm-m-t-20"
                target="_blank"
                noValidate
              >
                <div className="input-group email-wrapper">
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_f33e2360a713fdf68a46a5956_a9617170fc"
                      tabIndex="-1"
                    />
                  </div>
                  <div className="clear"></div>
                  <div className="input-group email-wrapper">
                    <input
                      placeholder="Your email"
                      type="email"
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                    />
                    <span className="input-group-btn">
                      <input
                        type="submit"
                        value="Go"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="button"
                      />
                    </span>
                  </div>
                </div>
                <div id="mce-responses" className="clear">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: "none" }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: "none" }}
                  ></div>
                </div>
              </form>
            </div>
          </div>
        </div>
        
      </div>

    
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
